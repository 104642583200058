import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import emailjs from 'emailjs-com'; // Import EmailJS SDK
import music from './assets/sound.mp3'; // Adjust the path as necessary
import bg from './assets/bg3.jpg'; // Adjust the path as necessary
import { supabase } from './supabaseClient';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';


const InvitationCard = () => {
  const location = useLocation();
  const [fromName, setFromName] = useState('')
  const [wish, setWish] = useState('');
  const { familyKey } = location.state || {}; // Get familyKey from state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isComing, setIsComing] = useState(false);
  const [familyData, setFamilyData] = useState(null);


  useEffect(() => {
    const fetchFamilyData = async () => {
      const { data, error } = await supabase
        .from('invitations')
        .select('*')
        .eq('key', familyKey)
        .single();

      if (data) {
        setFamilyData(data);
        setIsComing(data.coming);
        setFromName(data.family_name);
      } else {
        console.error('Error fetching family data:', error);
      }
    };

    if (familyKey) {
      fetchFamilyData();
    }
  }, [familyKey]);

  const handleWishChange = (e) => {
    setWish(e.target.value);
  };


  const handleWishSubmit = (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userId = process.env.REACT_APP_EMAILJS_USER_ID;
    const emailParams = {
      wish: wish,
      from_name: fromName,
      reply_to: process.env.REACT_APP_WISH_EMAIL_RECIPIENT // Optional: pass the recipient email as a variable
    };

    emailjs.send(serviceId, templateId, emailParams, userId)
      .then((response) => {
        console.log('Wish sent successfully!', response.status, response.text);
        setWish(''); // Clear the input after submission
        alert('Din hälsning har skickats!');
        closeModal();
      })
      .catch((err) => {
        console.error('Failed to send wish.', err);
        alert('Problem med att skicka din hälsning. Försök igen senare.');
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleComing = async () => {
    const updatedStatus = !isComing;

    const { error } = await supabase
      .from('invitations')
      .update({ coming: updatedStatus })
      .eq('key', familyKey);

    if (error) {
      console.error('Error updating coming status:', error);
    } else {
      setIsComing(updatedStatus);
      if (updatedStatus) {
        alert('Tack för att ni bekräftar att ni kommer!');
      } else {
        alert('Tråkigt att ni inte kan komma. Vi ses nästa gång!');
      }
    }
  };

  if (!familyData) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <ForestBackground>
        <TextWrapper>
          <Heading>
          <span>Välkommen till vår kära dotters dop</span><br />
          <span style={{ fontSize: '2rem' }}>Tula Naomi Nagy Ong</span>
          </Heading>
          <Description>
            Vi ser fram emot att fira denna speciella dag med er! <br />
            Fika kommer att serveras efter ceremonin.
          </Description>
          <SubHeading>Söndagen den 24 November kl.15</SubHeading>
          <Details>
          <span style={{ fontSize: '1rem' }}>Oscar Fredriks kyrkogata 1</span><br />
          <span style={{ fontSize: '1rem' }}>413 17, Göteborg <MapLink
              href="https://www.google.com/maps/dir/?api=1&destination=Oscar+Fredrik+Church,+Oscar+Fredriks+kyrkogata,+Gothenburg&travelmode=driving"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </MapLink></span>
          </Details>
          <SubHeading><span style={{ fontSize: '0.8rem'}}>OSA senast 4 November</span></SubHeading>
        </TextWrapper>
        <InstructionText>
          Tryck på <HeartText>hjärtat</HeartText> för att bekräfta att ni kommer
        </InstructionText>
        <FamilyNameText>{familyData.family_name}</FamilyNameText>
        <RSVPWrapper>
          <FontAwesomeIcon
            onClick={toggleComing}
            icon={isComing ? solidHeart : regularHeart}
            size="3x"
            color={isComing ? "#FF69B4" : "#FFFFFF"}
            style={{ cursor: 'pointer', transition: 'all 0.3s ease' }}
          />
        </RSVPWrapper>
        <WishButtonWrapper>
          <WishButton onClick={openModal}>Skriv gärna en hälsning till vår dotter</WishButton>
        </WishButtonWrapper>
        {isModalOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <WishForm onSubmit={handleWishSubmit}>
              <WishInput
                type="text"
                value={wish}
                onChange={handleWishChange}
                placeholder="Hälsning..."
              />
              <SubmitButton type="submit">Skicka</SubmitButton>
            </WishForm>
          </ModalContent>
        </ModalOverlay>
      )}
        <MediaPlayerWrapper>
          <ReactPlayer
            url={music} // Adjust the path as necessary
            playing
            loop
            volume={0.5}
            width='300px'
            height='50px'
            style={{ display: 'none' }} // Hide the player
          />
        </MediaPlayerWrapper>
      </ForestBackground>
    </Container>
  );
};

export default InvitationCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f3f3f3;
`;

const ForestBackground = styled.div`
  width: 100%;
  min-height: 100vh; /* Ensures the background covers the entire viewport height */
  background-image: url(${bg});
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; Moves content to the bottom
  padding: 20px;
  box-sizing: border-box;
`;

const FamilyNameText = styled.h3`
  font-size: 1rem;
  margin-bottom: 10px;
  color: white;
  font-family: 'Roboto';
`;

const TextWrapper = styled.div`
  text-align: center;
  color: white;
  margin-bottom: 5px; /* Ensure there's enough space for the input box */
`;

const Heading = styled.h1`
  font-size: 4rem;
  margin-bottom: 20px;
  font-family: 'Great Vibes', cursive;
`;

const SubHeading = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
  font-family: 'Roboto';
`;

const Details = styled.p`
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-family: 'Roboto';
`;

const Description = styled.p`
  font-size: 1.15rem;
  font-family: 'Roboto';
`;

const MediaPlayerWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`;

const MapLink = styled.a`
  margin-left: 10px;
  color: white;
  font-size: 1.5rem;
  text-decoration: none;

  &:hover {
    color: #ff6347; /* Optional: Change color on hover */
  }
`;

const WishButtonWrapper = styled.div`
  position: relative ;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WishButton = styled.button`
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  outline: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.5);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const InstructionText = styled.p`
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: white;
  font-family: 'Roboto';
`;

const HeartText = styled.span`
  color: #FF69B4;
  font-weight: bold;
  font-family: 'Roboto';
`;

const ModalContent = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  max-width: 400px;
  width: 100%;
  text-align: center;
`;

const WishForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WishInput = styled.input`
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  border: none;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  text-align: center;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  outline: none;
  transition: all 0.3s ease;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-style: italic;
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.5);
  }
`;

const SubmitButton = styled.button`
  padding: 15px 30px;
  margin-top: 15px;
  font-size: 1.2rem;
  border: none;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  outline: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.5);
  }
`;

const RSVPWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: heartbeat 1.5s infinite;

  @keyframes heartbeat {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }

  &:hover {
    transform: scale(1.2);
  }
`;
