import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PasswordPage from './PasswordPage';
import InvitationCard from './InvitationCard';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<PasswordPage />} />
      <Route path="/invitation" element={<InvitationCard />} />
    </Routes>
  </Router>
);
}

export default App;
