import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import anime from 'animejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const lavenderNeon = keyframes`
  0% { border-color: #E6E6FA; background-color: #E6E6FA; box-shadow: 0 0 10px #E6E6FA; }
  20% { border-color: #CDA4DE; background-color: #CDA4DE; box-shadow: 0 0 10px #CDA4DE; }
  40% { border-color: #B19CD9; background-color: #B19CD9; box-shadow: 0 0 10px #B19CD9; }
  60% { border-color: #A084CA; background-color: #A084CA; box-shadow: 0 0 10px #A084CA; }
  80% { border-color: #9370DB; background-color: #9370DB; box-shadow: 0 0 10px #9370DB; }
  100% { border-color: #8467D7; background-color: #8467D7; box-shadow: 0 0 10px #8467D7; }
`;

const pulse = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
`;

const ButterflyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;, ${pulse} 2s infinite;

  &:hover {
    transform: scale(1.2);
  }

  img {
    width: 40px;
    height: auto;
  }
`;

const lavenderShades = [
  'rgba(230, 230, 250, 0.5)', // Lavender
  'rgba(216, 191, 216, 0.5)', // Thistle
  'rgba(221, 160, 221, 0.5)', // Plum
  'rgba(238, 130, 238, 0.5)', // Violet
  'rgba(218, 112, 214, 0.5)', // Orchid
  'rgba(186, 85, 211, 0.5)',  // Medium Orchid
];

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 10px;
  outline: none;
  margin-right: 10px;
  transition: all 0.3s ease;
  color: white;
  font-family: 'Roboto';

  &.neon {
    animation: ${lavenderNeon} 2s linear infinite;
  }
`;

const PasswordPage = () => {
  const [enteredKey, setEnteredKey] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  const handleKeyChange = (e) => {
    setEnteredKey(e.target.value);
  };

  const handleKeySubmit = async (e) => {
    e.preventDefault();

    const { data, error } = await supabase
      .from('invitations')
      .select('key')
      .eq('key', enteredKey)
      .single();

    if (error && process.env.REACT_APP_MODE === "debug") {
      console.error('Error fetching data:', error);
      return;
    }


    if (data) {
      navigate('/invitation', { state: { familyKey: enteredKey } }); // Navigate to invitation route
    } else {
      setErrorMessage('Dålig nyckel. Försök igen.');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };


  return (
    <Container>
          <BubbleBackground />

        <FormWrapper>
        <Form onSubmit={handleKeySubmit} className={'neon'}>
        <InputWrapper>
        <Input
          type={showPassword ? 'text' : 'password'} // Toggle between text and password type
          value={enteredKey}
          onChange={handleKeyChange}
          className={'neon'}
        />
        <IconButton onClick={toggleShowPassword} type="button">
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </IconButton>
        </InputWrapper>
        <ButterflyButton type="submit">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#9370DB" width="24px" height="24px">
            <path d="M12 2C9.79 2 7.84 3.16 7.07 5.08C3.99 5.51 2 7.91 2 11c0 3.31 2.69 6 6 6h8c3.31 0 6-2.69 6-6 0-3.02-2.25-5.48-5.15-5.93C16.52 4.08 14.37 2 12 2zm0 16H8c-2.21 0-4-1.79-4-4 0-2.21 1.79-4 4-4 1.09 0 2.09.45 2.81 1.18C11.57 9.5 12.74 9 14 9c2.21 0 4 1.79 4 4 0 2.21-1.79 4-4 4zm4-6c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"/>
          </svg>
        </ButterflyButton>
        </Form>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      </FormWrapper>
    </Container>
  );
};

export default PasswordPage;

// Styled components

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

const IconButton = styled.button`
  position: absolute;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  color: white;

  &:focus {
    outline: none;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto';
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 15px;
  z-index: 10;  /* Ensure the form is above the bubbles */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100vh;
  background-color: #f3f3f3;
  position: relative;
  overflow: hidden; /* Make sure this is set */
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &.neon {
    animation: ${lavenderNeon} 2s linear infinite;
  }
`;

const bubbleAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
`;

const Bubble = styled.div`
  position: absolute;
  bottom: -50px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: ${bubbleAnimation} 5s infinite ease-in-out;
  pointer-events: none;
`;


const ErrorText = styled.p`
  margin-top: 20px;
  color: #e74c3c;
  font-size: 1rem;
  font-family: 'Roboto';
`;

const BubbleBackground = () => {
  const [bubbles, setBubbles] = useState([]);

  useEffect(() => {
    const generatedBubbles = Array.from({ length: 20 }, (_, i) => {
      const size = anime.random(30, 100);
      const randomShade = lavenderShades[anime.random(0, lavenderShades.length - 1)];
      return (
        <Bubble
          key={i}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            left: `${anime.random(0, 100)}%`,
            top: `${anime.random(0, 100)}%`,
            animationDelay: `${anime.random(0, 20) / 10}s`,
            backgroundColor: randomShade,
          }}
        />
      );
    });
    setBubbles(generatedBubbles);
  }, []); // Empty dependency array ensures this runs only once

  return <>{bubbles}</>;
};
